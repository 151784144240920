//React packages
import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from "history";

//React css
import './App.css';

//React components
import Login from "./component/auth/Login";
import Register from "./component/auth/Register";
import RegisterVerification from "./component/auth/RegisterVerification";
import ForgetPassword from "./component/auth/ForgetPassword";
import ResetPassword from "./component/auth/ResetPassword";
import Dashboard from "./component/dashboard/Index";
import Redeem from "./component/auth/Redeem"


//React Customized Routes
import { PrivateRoute } from "./customized-routes/PrivateRoute";
import { AdminRoute } from "./customized-routes/AdminRoute";
import { PublicRoute } from "./customized-routes/PublicRoute";
import NotSupported from "./component/NotSupported";


function App(props) {

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    //  Maintain router history
    const history = createBrowserHistory();

    return (
        <div className="App">
            <Router history={history}>

                {
                    isIE === true
                    ?
                        <Switch>
                                <Route path={'/'} exact component={NotSupported}/>
                                <Route component={() => {return <Redirect to='/' />}} />
                        </Switch>

                    :
                        <Switch>
                                <PublicRoute path={'/login'} exact component={Login} />
                                <PublicRoute path={'/forgot-password'} exact component={ForgetPassword} />
                                <PublicRoute path={'/register'} exact component={Register}/>
                                <PublicRoute path={'/verification/:hash'} exact component={RegisterVerification}/>
                                <PublicRoute path={'/reset-password/:hash'} exact component={ResetPassword}/>
                                <PublicRoute path={'/redeem'} exact component={Redeem} />
                                 
                                <PrivateRoute
                                    path={'/dashboard'}
                                    exact
                                    component={() => <Dashboard history={history}/>}
                                />
                                <AdminRoute
                                    path={'/:menu/users/all/'}
                                    exact
                                    component={() => <Dashboard history={history} />}
                                />
                                <AdminRoute
                                    path={'/:menu/user/edit/:id'}
                                    exact
                                    component={() => <Dashboard history={history} />}
                                />
                                <AdminRoute
                                    path={'/:menu/user/edit/:id/listcoupons'}
                                    exact
                                    component={() => <Dashboard history={history} />}
                                />
                                <AdminRoute
                                    path={'/:menu/user/edit/:id/listsusbscriptions'}
                                    exact
                                    component={() => <Dashboard history={history} />}
                                />
                                <AdminRoute
                                    path={'/:menu/user/edit/:id/generatecoupon'}
                                    exact
                                    component={() => <Dashboard history={history} />}
                                />
                                <PrivateRoute
                                    path={'/:menu/:submenu'}
                                    exact
                                    component={() => <Dashboard history={history}/>}
                                />
                                <PrivateRoute
                                    path={'/:menu/:submenu/:paramId'}
                                    exact
                                    component={() => <Dashboard history={history}/>}
                                />
                                <PrivateRoute
                                    path={'/:menu/:submenu/:paramId/:secondParamId'}
                                    exact
                                    component={() => <Dashboard history={history}/>}
                                />
                                <PrivateRoute
                                    path={'/:menu/:submenu/:paramId/:secondParamId/:thirdParamId'}
                                    exact
                                    component={() => <Dashboard history={history}/>}
                                />

                        </Switch>

                }
               
        </Router>
    </div>
  );
}

export default App;
